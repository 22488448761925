<template>
  <div>
    <!-- 内容区 -->
    <router-view />
    <!-- <van-tabbar route
                v-model="active">
      <van-tabbar-item replace
                       to="/home/home"
                       icon="home-o">标签1</van-tabbar-item>
      <van-tabbar-item replace
                       to="/second/second"
                       icon="search">标签2</van-tabbar-item>
      <van-tabbar-item replace
                       to="/third/third"
                       icon="friends-o">标签3</van-tabbar-item>
      <van-tabbar-item replace
                       to="/personal/personal"
                       icon="setting-o">个人中心</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data () {
    return {
      msg: 'Hello World!',
      active: 0
    }
  },
  created () { },
  mounted () { },
  methods: {}
}
</script>
<style lang="scss" scoped>
</style>